import React from 'react';
import { connect } from 'react-redux';
import SignModalContainer from './sign-modal-container';
import {
  setUserData,
  setSignedIn,
} from '../../../state/slices/userSlice';

function SignModalRedux(props) {
  return <SignModalContainer {...props} />;
}

const mapStateToProps = (state) => ({
  signedIn: state.user.signedIn,
  userData: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  setSignedIn: (val) => dispatch(setSignedIn(val)),
  setUserData: (val) => dispatch(setUserData(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignModalRedux);
