/* eslint-disable no-undef */
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './firebase';

const apiEndpoint = 'https://1704m2sq0f.execute-api.us-east-1.amazonaws.com/v1';

export const getUserById = async (userId) => (
  new Promise((resolve, reject) => {
    fetch(`${apiEndpoint}/get-entities`, {
      method: 'POST',
      body: JSON.stringify({
        data: [{
          type: 'user',
          data: {
            userId,
          },
        }],
      }),
      base64Encoded: false,
    })
      .then((res) => res.json())
      .then((res) => {
        resolve({
          ...res[0],
          id: res[0].userId,
        });
      })
      .catch((err) => reject(err));
  })
);

export const getSignedInUser = async () => (
  new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user && user.uid) {
        // User is signed in.
        getUserById(user.uid)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        // No user is signed in.
        reject();
      }
    });
  })
);

export const logout = async () => (
  new Promise((resolve, reject) => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        resolve();
      }).catch((err) => {
        // An error occurred.
        reject(err);
      });
  })
);
