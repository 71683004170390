/* eslint-disable no-undef */
import React from 'react';
import {
  Flex, Image, Text, Button, Link, Spacer, IconButton, useColorMode,
} from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';
import LogoImage from '../../media/images/logo.png';
import SignModal from '../modals/sign-modal';

const getProfileLink = (userId) => `/profile?id=${userId}`;

function HeaderView({
  modalType,
  modalOpen,
  onCloseModal,
  onLogoutClicked,
  onSignInClick,
  onSignUpClick,
  onSwapModalType,
  signedIn,
  userData,
}) {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <>
      <Flex
        as="header"
        width="100%"
        padding={4}
        alignItems="center"
        justifyContent="space-between"
        direction={['column', 'row']}
        color={colorMode === 'light' ? 'black' : 'white'}
      >
        <Flex
          cursor="pointer"
          display="flex"
          alignItems="center"
          direction="row"
          marginTop={[4, 0]}
        >
          <Image
            src={LogoImage}
            alt="logo"
            width={16}
            height={16}
          />
          <Flex
            direction="column"
            justify="center"
            marginLeft={2}
          >
            <Text
              as="h1"
              fontSize={32}
              lineHeight={8}
              onClick={() => { window.location.replace('/'); }}
              role="button"
              tabIndex={0}
              margin={0}
              onKeyDown={(e) => e.code === 'Enter' && window.location.replace('/')}
            >
              OutHereGaming
            </Text>
            <Text fontSize={18}>
              ... we&apos;re&nbsp;
              <i>out here</i>
            </Text>
          </Flex>
        </Flex>
        <Spacer />
        <Flex
          display="flex"
          alignItems={['center', 'flex-end']}
          justifyContent="center"
          direction="column"
          textAlign={['center', 'right']}
          marginTop={[4, 0]}
          width="100%"
        >
          { signedIn && userData
            ? (
              <Flex direction="column" align="center" justify="center">
                <Text marginBottom={2}>
                  Welcome back&nbsp;
                  <Link
                    href={getProfileLink(userData.id)}
                    fontWeight="bold"
                  >
                    {userData.id}
                  </Link>
                  !
                </Text>
                <Flex direction="row" align="center" justify="center">
                  <Button
                    colorScheme="gray"
                    width="full"
                    marginY={2}
                    onClick={() => onLogoutClicked()}
                    onKeyDown={(e) => e.code === 'Enter' && onLogoutClicked()}
                  >
                    Log out
                  </Button>
                  <IconButton
                    aria-label="Toggle Dark Switch"
                    icon={colorMode === 'dark' ? <SunIcon /> : <MoonIcon />}
                    onClick={toggleColorMode}
                    margin={2}
                  />
                </Flex>
              </Flex>
            ) : (
              <Flex direction="row" align="center" justify="center">
                <Button
                  colorScheme="gray"
                  width="full"
                  margin={2}
                  onClick={() => onSignInClick()}
                  onKeyDown={(e) => e.code === 'Enter' && onSignInClick()}
                >
                  Sign In
                </Button>
                <Button
                  colorScheme="gray"
                  width="full"
                  margin={2}
                  onClick={() => onSignUpClick()}
                  onKeyDown={(e) => e.code === 'Enter' && onSignUpClick()}
                >
                  Sign Up
                </Button>
                <IconButton
                  aria-label="Toggle Dark Switch"
                  icon={colorMode === 'dark' ? <SunIcon /> : <MoonIcon />}
                  onClick={toggleColorMode}
                  margin={2}
                />
              </Flex>
            )}
        </Flex>
      </Flex>
      { modalOpen && (
        <SignModal
          modalType={modalType}
          onCloseModal={onCloseModal}
          onSwapModalType={onSwapModalType}
        />
      )}
    </>
  );
}

export default HeaderView;
