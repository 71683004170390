/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tab: 'Home',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },
  },
});

export const { setTab } = appSlice.actions;
export const selectTab = (state) => state.app.tab;
export default appSlice.reducer;
