import React from 'react';
import {
  Box,
} from '@chakra-ui/react';
import Header from '../header';
import Footer from '../footer';
import HeaderHero from '../header-hero';
import './reset.scss';

function Layout({ children }) {
  return (
    <Box
      margin="auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Header />
      <HeaderHero />
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
}

export default Layout;
