import React from 'react';
import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

function SignModalView({
  email,
  error,
  fetching,
  finishing,
  modalType,
  onCancel,
  onFinish,
  onSubmit,
  onSwapModalType,
  setEmail,
  success,
}) {
  return (
    <Modal isOpen onClose={onCancel}>
      <ModalOverlay />
      <ModalContent
        marginX={{ base: 4, md: 0 }}
        marginY="auto"
      >
        <ModalBody padding={6}>
          <Center marginBottom={4}>
            { success
              ? <SuccessHeader />
              : <DefaultHeader modalType={modalType} onSwapModalType={onSwapModalType} />}
          </Center>
          { !fetching && (
            <>
              { !success && <EmailInput setInput={setEmail} /> }
              { error && <ErrorMessage message={error} /> }
              { !success && (
                <Center marginTop={4}>
                  <SubmitButton
                    email={email}
                    onClick={onSubmit}
                  />
                  <CancelButton
                    onClick={onCancel}
                  />
                </Center>
              )}
            </>
          )}
          { fetching && <Loading /> }
          { success && (
            <Flex direction="column" alignItems="center">
              <SuccessMessage message={success} />
              { finishing && <FinishMessage onClick={onFinish} /> }
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function Loading() {
  return (
    <Center>Loading...</Center>
  );
}

function SubmitButton({ email, onClick }) {
  return (
    <Button
      colorScheme="orange"
      isDisabled={!email}
      onKeyDown={(e) => e.code === 'Enter' && onClick()}
      onClick={onClick}
      marginRight={2}
    >
      Submit
    </Button>
  );
}

function CancelButton({ onClick }) {
  return (
    <Button
      colorScheme="gray"
      onKeyDown={(e) => e.code === 'Enter' && onClick()}
      onClick={onClick}
    >
      Cancel
    </Button>
  );
}

function EmailInput({ setInput }) {
  return (
    <FormControl id="email" marginBottom={4}>
      <FormLabel>Email</FormLabel>
      <Input
        placeholder="Example@user.com"
        onChange={(e) => setInput(e.target.value)}
      />
    </FormControl>
  );
}

function DefaultHeader({ modalType, onSwapModalType }) {
  return (
    <>
      <Heading
        as="h2"
        fontSize={24}
        marginBottom={2}
      >
        {modalType === 'signin' ? 'Sign In' : 'Sign Up'}
      </Heading>
      <Text>
        ... or&nbsp;
        <Text
          as="span"
          color="skyblue"
          tabIndex={0}
          role="button"
          onKeyDown={(e) => e.code === 'Enter' && onSwapModalType()}
          onClick={() => onSwapModalType()}
        >
          {modalType !== 'signin' ? 'Sign In' : 'Sign Up'}
        </Text>
        {' '}
        instead
      </Text>
    </>
  );
}

function SuccessHeader() {
  return (
    <Heading as="h2" fontSize={24}>
      Finishing sign in
    </Heading>
  );
}

function ErrorMessage({ message }) {
  return (
    <Text color="red" marginBottom={4}>
      {message}
    </Text>
  );
}

function SuccessMessage({ message }) {
  return (
    <Text color="limegreen" marginBottom={4} textAlign="center">
      {message}
    </Text>
  );
}

function FinishMessage({ onClick }) {
  return (
    <Button
      colorScheme="orange"
      onKeyDown={(e) => e.code === 'Enter' && onClick()}
      onClick={onClick}
    >
      Get Started!
    </Button>
  );
}

export default SignModalView;
