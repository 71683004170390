import React from 'react';
import { connect } from 'react-redux';
import ProfileContainer from './profile-container';

function ProfileRedux(props) {
  return <ProfileContainer {...props} />;
}

const mapStateToProps = (state) => ({
  signedIn: state.user.signedIn,
  userData: state.user.userData,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileRedux);
