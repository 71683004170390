import React from 'react';
import {
  Box, Link, Text, VStack, useColorMode,
} from '@chakra-ui/react';

const socialMediaLinks = [
  {
    name: 'TikTok',
    url: 'https://www.tiktok.com/@outheregaming',
    user: '@outheregaming',
  },
  {
    name: 'Twitter',
    url: 'https://www.twitter.com/@gaming_outhere',
    user: '@gaming_outhere',
  },
];

const boxStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: 4,
  borderRadius: 4,
  width: '100%',
  maxWidth: '768px',
};

const innerBoxStyles = {
  fontFamily: 'Open Sans',
  width: '100%',
  padding: 4,
  textAlign: 'left',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: 4,
};

function Footer() {
  const { colorMode } = useColorMode();

  return (
    <Box
      {...boxStyles}
    >
      <Box
        {...innerBoxStyles}
        color={colorMode === 'light' ? 'black' : 'white'}
        backgroundColor={colorMode !== 'light' ? 'black' : '#e2e8f0'}
      >
        <Text marginBottom={4}>
          Follow us on social media:
        </Text>
        <VStack
          align="start"
          spacing={1}
        >
          {socialMediaLinks.map(({ name, url, user }) => (
            <Text key={name}>
              <Text
                as="span"
                fontWeight="bold"
              >
                {`${name}: `}
              </Text>
              <Link
                href={url}
                isExternal
              >
                {user}
              </Link>
            </Text>
          ))}
        </VStack>
        <Text marginTop={4}>
          ©
          {' '}
          {new Date().getFullYear()}
          , OutHereGaming
        </Text>
      </Box>
    </Box>
  );
}

export default Footer;
