/* eslint-disable no-undef */
import React, { useState, useEffect, useCallback } from 'react';
import {
  getAdditionalUserInfo,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from 'firebase/auth';
import SignModalView from './sign-modal-view';
import { auth } from '../../../firebase/firebase';

function SignModalContainer({
  modalType,
  onCloseModal,
  onSwapModalType,
  setSignedIn,
  setUserData,
}) {
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [fetching, setFetching] = useState(false);
  const [finishing, setFinishing] = useState();
  const [success, setSuccess] = useState();

  const apiEndpoint = 'https://1704m2sq0f.execute-api.us-east-1.amazonaws.com/v1';
  const returnURI = 'https://www.outheregaming.com'; // http://localhost:3000;

  //  Docs: https://firebase.google.com/docs/auth/web/email-link-auth
  const actionCodeSettings = {
    // URL must be whitelisted in the Firebase Console.
    url: `${returnURI}/?finish=true`,
    handleCodeInApp: true,
  };

  const createNewUserInDB = useCallback((emailForSignIn, firebaseUid) => {
    setFetching(true);
    fetch(`${apiEndpoint}/update-entities`, {
      method: 'POST',
      body: JSON.stringify({
        data: [{
          id: -1,
          data: {
            email: emailForSignIn,
            userId: firebaseUid,
          },
          type: 'user',
        }],
      }),
      base64Encoded: false,
    })
      .then((res) => res.json())
      .catch((err) => {
        setUserData(null);
        setSignedIn(false);
        setError(err);
        //  Keep modal open so user can read error
      })
      .finally(() => {
        setFetching(false);
      });
  }, [
    setSignedIn,
    setUserData,
  ]);

  const signIn = useCallback(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      setFetching(true);
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let emailForSignIn = window.localStorage.getItem('emailForSignIn');
      if (!emailForSignIn) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        // eslint-disable-next-line no-alert
        emailForSignIn = window.prompt('Please provide your email for confirmation');
      }

      signInWithEmailLink(auth, emailForSignIn, window.location.href)
        .then((result) => {
          // Clear email from storage.
          //  window.localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user

          setSuccess('Successfully signed in!');

          //  If new user, importantly, add to db.
          const details = getAdditionalUserInfo(result);
          if (details.isNewUser) {
            createNewUserInDB(emailForSignIn, result.user.uid);
          }
        })
        .catch((err) => {
          setUserData(null);
          setSignedIn(false);
          setError(err.message);
        })
        .finally(() => {
          setFetching(false);
        });
    } else {
      //  Do nothing, user is not coming from sign in link.
    }
  }, [
    createNewUserInDB,
    setSignedIn,
    setUserData,
  ]);

  const validateForm = () => {
    if (!email) {
      return {
        error: { message: 'Invalid email' },
      };
    }
    return true;
  };

  const onSubmit = () => {
    setFetching(true);

    const result = validateForm();
    if (result.error) {
      setUserData(null);
      setSignedIn(false);
      setError(result.error.message);
      setFetching(false);
      return;
    }

    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        setError();
        setSuccess(`Link was sent successfully.${'\n'}Please open the link sent to ${email} to proceed!`);

        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
      })
      .catch((err) => {
        setUserData(null);
        setSignedIn(false);
        setError(err.message);
      })
      .finally(() => {
        setFetching(false);
      });
  };

  const onFinish = () => {
    window.location = '/';
  };

  useEffect(() => {
    //  Parse url and check if user is trying to sign in
    if (typeof window !== 'undefined') {
      setFinishing(window.location.search.includes('finish=true'));
    }
  }, []);

  useEffect(() => {
    if (finishing) {
      signIn();
    }
  }, [finishing, signIn]);

  return (
    <SignModalView
      email={email}
      error={error}
      fetching={fetching}
      finishing={finishing}
      modalType={modalType}
      onCancel={onCloseModal}
      onFinish={onFinish}
      onSubmit={onSubmit}
      onSwapModalType={onSwapModalType}
      setEmail={setEmail}
      success={success}
    />
  );
}

export default SignModalContainer;
