import React from 'react';
import {
  Box, Flex, VStack, Heading, Text, useColorMode,
} from '@chakra-ui/react';

function ProfileView({ errorMessage, userData }) {
  const { colorMode } = useColorMode();

  return (
    <Box maxWidth="768px" width="100%">
      {errorMessage && (
        <VStack
          padding={4}
          margin={{ base: '16px 8px', md: '28px' }}
          borderRadius={4}
          backgroundColor={colorMode !== 'light' ? 'black' : 'white'}
          color={colorMode === 'light' ? 'black' : 'white'}
        >
          <Text>{errorMessage}</Text>
        </VStack>
      )}
      {userData && (
        <Box>
          <Box label="Overview">
            <VStack
              alignItems="flex-start"
              padding={4}
              margin={4}
              borderRadius={4}
              backgroundColor={colorMode !== 'light' ? 'black' : 'white'}
              color={colorMode === 'light' ? 'black' : 'white'}
            >
              <Heading fontSize={24}>Account Overview</Heading>
              <Flex>
                <Box flex={1}>
                  <Text>Id:</Text>
                </Box>
                <Box flex={1}>
                  <Text>{userData.id}</Text>
                </Box>
              </Flex>
              <Flex>
                <Box flex={1}>
                  <Text>Email:</Text>
                </Box>
                <Box flex={1}>
                  <Text>{userData.email}</Text>
                </Box>
              </Flex>
            </VStack>
          </Box>
          <Box label="Settings">
            <VStack
              alignItems="flex-start"
              padding={4}
              margin={4}
              borderRadius={4}
              backgroundColor={colorMode !== 'light' ? 'black' : 'white'}
              color={colorMode === 'light' ? 'black' : 'white'}
            >
              <Heading fontSize={24}>Account Settings</Heading>
              <Text>There is nothing here.</Text>
            </VStack>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ProfileView;
