import React, { useEffect, useState } from 'react';
import HeaderView from './header-view';
import { getSignedInUser, logout } from '../../firebase/utils';

function HeaderContainer({
  setSignedIn,
  setUserData,
  signedIn,
  userData,
}) {
  //  Check if user is returning from sign in. Need to check here to show modal on return.
  let checkFinish;
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-undef
    checkFinish = window.location.search.includes('finish=true');
  }

  const [modalType, setModalType] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (checkFinish) {
      setModalOpen(true);
    }

    if (!signedIn) {
      getSignedInUser()
        .then((res) => {
          setUserData(res);
          setSignedIn(true);
        })
        .catch(() => {
          setUserData();
          setSignedIn(false);
        });
    }
  }, [
    checkFinish,
    setUserData,
    setSignedIn,
    signedIn,
    userData,
  ]);

  const onSignInClick = () => {
    setModalType('signin');
    setModalOpen(true);
  };

  const onSignUpClick = () => {
    setModalType('signup');
    setModalOpen(true);
  };

  const onSwapModalType = () => {
    if (modalType === 'signin') {
      setModalType('signup');
      return;
    }
    setModalType('signin');
  };

  const onLogoutClicked = () => {
    logout()
      .then(() => {
        setSignedIn(false);
        setUserData();
        // window.location="/";
      })
      .catch(() => {
        // window.location="/";
      });
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <HeaderView
      modalType={modalType}
      modalOpen={modalOpen}
      onCloseModal={onCloseModal}
      onLogoutClicked={onLogoutClicked}
      onSignInClick={onSignInClick}
      onSignUpClick={onSignUpClick}
      onSwapModalType={onSwapModalType}
      signedIn={signedIn}
      userData={userData}
    />
  );
}

export default HeaderContainer;
