import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Box, Heading, Link } from '@chakra-ui/react';

function NotFoundPage() {
  return (
    <Box padding={5}>
      <Heading
        as="h1"
        fontSize={32}
        textAlign="center"
        marginY={4}
      >
        NOT FOUND
      </Heading>
      <Box textAlign="center">
        <Link
          as={ReactLink}
          to="/"
        >
          Click here to return home
        </Link>
      </Box>
    </Box>
  );
}

export default NotFoundPage;
