import React from 'react';
import { connect } from 'react-redux';
import HeaderContainer from './header-container';
import {
  setSignedIn,
  setUserData,
} from '../../state/slices/userSlice';

function HeaderRedux(props) {
  return <HeaderContainer {...props} />;
}

const mapStateToProps = (state) => ({
  signedIn: state.user.signedIn,
  userData: state.user.userData,
});

const mapDispatchToProps = (dispatch) => ({
  setSignedIn: (val) => dispatch(setSignedIn(val)),
  setUserData: (val) => dispatch(setUserData(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderRedux);
