import React from 'react';
import {
  BrowserRouter as Router, Route, Routes,
} from 'react-router-dom';
import { ChakraProvider, ColorModeScript, extendTheme } from '@chakra-ui/react';
import About from './pages/about';
import Profile from './pages/profile';
import Four04 from './pages/404';
import Layout from './components/layout';

const theme = extendTheme({
  fonts: {
    heading: 'Open Sans, sans-serif',
    body: 'Open Sans, sans-serif',
  },
  initialColorMode: 'dark',
  useSystemColorMode: false,
});

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode="dark" />
      <Router>
        <Layout>
          <Routes>
            <Route exact path="/" element={<About />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route path="*" element={<Four04 />} />
          </Routes>
        </Layout>
      </Router>
    </ChakraProvider>
  );
}
