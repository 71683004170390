import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import HeaderHero from '../../media/images/header2.png';

function HeroImage() {
  return (
    <Image
      w="100%"
      h="100%"
      objectFit="cover"
      src={HeaderHero}
      alt="header"
    />
  );
}

function HeaderHeroView() {
  return (
    <Box
      width="100%"
      height="400px"
      overflow="hidden"
      position="relative"
    >
      <HeroImage />
      <Box
        position="absolute"
        top="0"
        bottom="0"
        left="0"
        right="0"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgGradient="linear(to-b, rgba(0,0,0,0), rgba(0,0,0,1.0))"
      >
        <Text
          fontSize={48}
          color="white"
          textShadow="4px 4px 12px lightslategrey"
          textAlign="center"
          margin="0"
        >
          Gaming. Competing. Having Fun.
        </Text>
      </Box>
    </Box>
  );
}

export default HeaderHeroView;
