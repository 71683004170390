import React from 'react';
import { Box, Text, useColorMode } from '@chakra-ui/react';

const boxStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  marginTop: 4,
  padding: 4,
  borderRadius: 4,
  maxWidth: '768px',
};

const innerBoxStyles = {
  fontFamily: 'Open Sans',
  width: '100%',
  padding: 4,
  textAlign: 'left',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: 4,
};

function About() {
  const { colorMode } = useColorMode();

  return (
    <Box
      {...boxStyles}
    >
      <Box
        {...innerBoxStyles}
        color={colorMode === 'light' ? 'black' : 'white'}
        backgroundColor={colorMode !== 'light' ? 'black' : '#e2e8f0'}
      >
        <Text fontSize={16} my={4}>
          Born amidst a global crisis, our mission is to redefine the meaning of gaming.
          <br />
          <br />
          We cultivate a high-energy, inclusive community of talented gamers, create
          extraordinary and engaging content, and host our own competitive tournaments.
          We believe in harnessing our collective skills to dominate the eSports realm,
          all while celebrating the joy and camaraderie of gaming.
          With us, it&apos;s more than a game;
          it&apos;s a journey, a lifestyle, a transformation of the ordinary into the extraordinary.
          <br />
          <br />
          Join us, let&apos;s redefine gaming together.
        </Text>
      </Box>
    </Box>
  );
}

export default About;
