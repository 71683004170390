/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  signedIn: false,
  userData: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSignedIn: (state, action) => {
      state.signedIn = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
});

export const { setSignedIn, setUserData } = userSlice.actions;
export const selectUserData = (state) => state.user.userData;
export const selectSignedIn = (state) => state.user.signedIn;
export default userSlice.reducer;
