import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileView from './profile-view';
import { getUserById } from '../../firebase/utils';

function ProfileContainer({
  signedIn,
  userData,
}) {
  const [errorMessage, setErrorMessage] = useState();
  const [displayedUserData, setDislayedUserData] = useState();
  const navigation = useNavigate();

  const parseParams = (paramString) => {
    const paramStringLocal = paramString.replace('?', '');

    const finalObjs = [];
    const keyValPairs = paramStringLocal.split('&');
    for (let i = 0; i < keyValPairs.length; i += 1) {
      const obj = keyValPairs[i].split('=');
      finalObjs.push({
        [obj[0]]: obj[1],
      });
    }
    return finalObjs;
  };

  useEffect(() => {
    if (signedIn && !!userData) {
      // eslint-disable-next-line no-undef
      const params = parseParams(window.location.search);
      const filterForId = params.filter((keyvals) => keyvals.id);
      if (filterForId.length > 0) {
        const { id } = filterForId[0];
        //  Set if private or not
        if (userData.id === id) {
          //  Looking at self
          setDislayedUserData(userData);
        } else {
          //  Looking at another user, get their data
          getUserById(id)
            .then((res) => setDislayedUserData(res))
            .catch(() => setErrorMessage('No user found with that id.'));
        }
        setErrorMessage();
      } else {
        //  No id was found, redirect
        navigation(`/profile?id=${userData.id}`);
      }
    } else {
      setErrorMessage('You must be signed in to view profiles.');
    }
  }, [
    navigation,
    signedIn,
    userData,
  ]);

  return (
    <ProfileView
      errorMessage={errorMessage}
      userData={displayedUserData}
    />
  );
}

export default ProfileContainer;
